import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "p-10 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfViewer = _resolveComponent("PdfViewer")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.pdf && _ctx.pdf.length)
      ? (_openBlock(), _createBlock(_component_PdfViewer, {
          key: 0,
          pdf: _ctx.pdf,
          class: "w-full h-full"
        }, null, 8, ["pdf"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PageLoading)
        ]))
  ]))
}