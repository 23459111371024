
import { Vue, Options } from 'vue-class-component';
import { PageLoading, PdfViewer } from '@/lib/components';
import { PatientDocumentService, PatientReviewService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },

    reviewId: {
      type: String,
      required: true
    }
  },
  components: {
    PageLoading,
    PdfViewer
  }
})
export default class PatientLetter extends Vue {
  patientId!: string;
  reviewId!: string;
  patientReviewService = new PatientReviewService(this.patientId);
  patientDocumentService = new PatientDocumentService(this.patientId);
  pdf = '';
  notificationStore = useNotificationStore();

  async mounted() {
    try {
      await this.fetchPatientReview();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async fetchPatientReview() {
    const response = await this.patientReviewService.fetch(this.reviewId);
    const review = response.data;

    // TODO: what if a review has multiple documents?
    if (review?.documents?.length > 0) {
      this.getPdf(review.documents[review.documents.length - 1].id);
    }
  }

  async getPdf(id: string) {
    try {
      const response = await this.patientDocumentService.fetchBase64Pdf(id);

      this.pdf = `data:application/pdf;base64,${response}`;
    } catch (e) {
      this.$emit('close', e);
    }
  }
}
